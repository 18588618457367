import { Fragment, useState } from 'react';
import { Dialog, Disclosure, Popover, Transition } from '@headlessui/react';
import logo from "../assets/hapkido-logo.png";
import { FaFacebookF, FaInstagram } from "react-icons/fa";
import { AiFillYoutube } from "react-icons/ai";


import {
  MenuAlt2Icon, // Update the import to BarsIcon as Bars3Icon
  XIcon as XMarkIcon,
  UserGroupIcon,
  GlobeIcon,
  UserIcon,
  CalendarIcon,
  PuzzleIcon,
  BookOpenIcon,
  CameraIcon
, // Update the import to XIcon as XMarkIcon
} from '@heroicons/react/outline'; // Update the path to '@heroicons/react/outline'

import { ChevronDownIcon } from '@heroicons/react/solid'; // Update the path to '@heroicons/react/solid'


const aboutUsItems = [
  { name: 'What is Hapkido', href: '/hapkido', icon: GlobeIcon },
  { name: 'Korean Phrases and Dojang Etiquette', href: 'dojang-etiquette', icon: BookOpenIcon},
  { name: 'Grandmaster',  href: '/grandmaster', icon: UserIcon },
  { name: 'Instructors',  href: '/instructors', icon: UserGroupIcon },

]

const classesItems = [
  { name: 'Our Curriculum', href: '/our-curriculum', icon: PuzzleIcon },
  { name: 'Class Schedule',  href: '/class-schedule', icon: CalendarIcon },
  { name: 'Upcoming Events',  href: '/events', icon: CameraIcon },

]

const socialLinks = [
  { icon: <FaInstagram />, link: 'https://www.instagram.com/songshapkidowestla' },
  { icon: <AiFillYoutube />, link: 'https://youtube.com/@SongsHapkidoWLA?si=Tiu2iKtN-pjdvIYd' },
  { icon: <FaFacebookF />, link: 'https://www.facebook.com/profile.php?id=61552637982137' },
  
];

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

export default function NavigationData() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false)

  return (
    <header className="bg-white">
       <nav className="mx-auto flex max-w-full items-center justify-between p-6 lg:px-8" aria-label="Global">
      <div className="flex items-center space-x-4 px-4">
        <a href="/" className="-m-1.5 p-1.5">
          <span className="sr-only">Song's Hapkido</span>
          <img className="h-20 w-auto" src={logo} alt="" />
        </a>
      </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-700"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <MenuAlt2Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        
        <Popover.Group className="hidden lg:flex lg:gap-x-12">
          <a href="/" className="px-4 text-lg font-semibold leading-6 text-gray-900 hover:text-yellow-600">
            Home
          </a>
          <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-lg font-semibold leading-6 text-gray-900">
              About Us
              <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {aboutUsItems.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon className="h-6 w-6 text-gray-600 group-hover:text-yellow-600" aria-hidden="true" />
                      </div>
                      <div className="flex-auto">
                        <a href={item.href} className="block font-semibold text-gray-900">
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
               
              </Popover.Panel>
            </Transition>
          </Popover>

          <Popover className="relative">
            <Popover.Button className="flex items-center gap-x-1 text-lg font-semibold leading-6 text-gray-900">
              Classes & Events
              <ChevronDownIcon className="h-5 w-5 flex-none text-gray-400" aria-hidden="true" />
            </Popover.Button>

            <Transition
              as={Fragment}
              enter="transition ease-out duration-200"
              enterFrom="opacity-0 translate-y-1"
              enterTo="opacity-100 translate-y-0"
              leave="transition ease-in duration-150"
              leaveFrom="opacity-100 translate-y-0"
              leaveTo="opacity-0 translate-y-1"
            >
              <Popover.Panel className="absolute -left-8 top-full z-10 mt-3 w-screen max-w-md overflow-hidden rounded-3xl bg-white shadow-lg ring-1 ring-gray-900/5">
                <div className="p-4">
                  {classesItems.map((item) => (
                    <div
                      key={item.name}
                      className="group relative flex items-center gap-x-6 rounded-lg p-4 text-sm leading-6 hover:bg-gray-50"
                    >
                      <div className="flex h-11 w-11 flex-none items-center justify-center rounded-lg bg-gray-50 group-hover:bg-white">
                        <item.icon className="h-6 w-6 text-gray-600 group-hover:text-yellow-600" aria-hidden="true" />
                      </div>
                      <div className="flex-auto">
                        <a href={item.href} className="block font-semibold text-gray-900">
                          {item.name}
                          <span className="absolute inset-0" />
                        </a>
                        <p className="mt-1 text-gray-600">{item.description}</p>
                      </div>
                    </div>
                  ))}
                </div>
               
              </Popover.Panel>
            </Transition>
          </Popover>

        
          <a href="/media-gallery" className="text-lg font-semibold leading-6 text-gray-900 hover:text-yellow-600">
            Media Gallery
          </a>
          {/*
          
          <a href="/reviews" className="text-lg font-semibold leading-6 text-gray-900 hover:text-yellow-600">
            Reviews
          </a>
                  */}
          <a href="/contact-us" className="text-lg font-semibold leading-6 text-gray-900 hover:text-yellow-600">
            Contact Us
          </a>
          <span className="mx-2"></span>
        </Popover.Group>

        <div className="hidden lg:flex lg:gap-x-4">
          {socialLinks.map((item, index) => (
             <a
             key={index}
             href={item.link}
             target="_blank"
             rel="noopener noreferrer"
             className="text-lg font-semibold text-gray-900 hover:text-white flex items-center gap-2"
           >
             <span className="p-2 rounded-full bg-[#efefef] hover:bg-red-800 transition duration-300">
               {item.icon}
             </span>
           </a>
          ))}
        </div>
       
      </nav>
      <Dialog as="div" className="lg:hidden" open={mobileMenuOpen} onClose={setMobileMenuOpen}>
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-white px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-gray-900/10">
          <div className="flex items-center justify-between">
            <a href="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Song's Hapkido</span>
              <img
                className="h-20 w-auto"
                src={logo}
                alt=""
              />
            </a>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-700"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/10">
              <div className="space-y-2 py-6">
                <a
                  href="/"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:text-yellow-600"
                >
                  Home
                </a>
                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                    
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        About Us
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {[...aboutUsItems].map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50  hover:text-yellow-600"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>

                      
                    </>
                  )}
                </Disclosure>
              

                <Disclosure as="div" className="-mx-3">
                  {({ open }) => (
                    <>
                      <Disclosure.Button className="flex w-full items-center justify-between rounded-lg py-2 pl-3 pr-3.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50">
                        Classes & Events
                        <ChevronDownIcon
                          className={classNames(open ? 'rotate-180' : '', 'h-5 w-5 flex-none')}
                          aria-hidden="true"
                        />
                      </Disclosure.Button>
                      <Disclosure.Panel className="mt-2 space-y-2">
                        {classesItems.map((item) => (
                          <Disclosure.Button
                            key={item.name}
                            as="a"
                            href={item.href}
                            className="block rounded-lg py-2 pl-6 pr-3 text-sm font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:text-yellow-600"
                          >
                            {item.name}
                          </Disclosure.Button>
                        ))}
                      </Disclosure.Panel>
                    </>
                  )}
                </Disclosure>
                <a
                  href="/media-gallery"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50  hover:text-yellow-600"
                >
                  Media Gallery
                </a>
                {/*
                <a
                  href="/reviews"
                  className="-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:text-yellow-600"
                >
                  Reviews
                </a>
                        */}
              </div>
              <div className="py-6">
                <a
                  href="/contact-us"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 text-gray-900 hover:bg-gray-50 hover:text-yellow-600"
                >
                  Contact Us
                </a>

                <div className="py-2 flex gap-2 items-center">
                  {socialLinks.map((item, index) => (
                    <a
                      key={index}
                      href={item.link}
                      target="_blank"
                      rel="noopener noreferrer"
                      className="text-lg font-semibold text-gray-900 hover:text-white flex items-center gap-2"
                    >
                      <span className="p-2 rounded-full bg-[#efefef] hover:bg-red-800 transition duration-300">
                        {item.icon}
                      </span>
                    </a>
                  ))}
                </div>

              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  )
}
